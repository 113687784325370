<template>
  <el-form ref="editor" class="resource-detail" :model="model" label-width="120px">
    <el-form-item label="资源类型">
      <el-select v-model="model.resourceType" placeholder="请选择" @change="handleChangeType">
        <el-option label="菜单" value="menu" />
        <el-option label="按钮" value="button" />
      </el-select>
    </el-form-item>
    <el-form-item label="资源名称">
      <el-input v-model="model.resourceName" />
    </el-form-item>
    <el-form-item label="资源Icon">
      <el-input v-model="model.resourceIcon" />
    </el-form-item>
    <el-form-item label="是否启用">
      <el-switch v-model="model.isEnable" />
    </el-form-item>
    <el-form-item label="上级菜单">
      <el-cascader
        v-model="model.resourceParentId"
        :options="menus"
        :props="props"
        @change="handleChangeParentId"
      />
    </el-form-item>

    <el-divider />

    <template v-if="model.resourceType === 'menu'">
      <el-form-item label="菜单路径">
        <el-input v-model="model.menuPath" />
      </el-form-item>
      <el-form-item label="资源排序">
        <el-input-number v-model="model.orderSort" size="small" />
      </el-form-item>
      <el-form-item label="资源组件">
        <el-input v-model="model.component" />
      </el-form-item>
    </template>

    <template v-if="model.resourceType === 'button'">
      <el-form-item label="按钮样式">
        <el-select v-model="model.extra.buttonType" placeholder="请选择">
          <el-option
            v-for="item in buttonTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="插槽">
        <el-input v-model="model.slot" />
      </el-form-item>
      <el-divider />
      <el-form-item label="按钮动作">
        <el-select v-model="model.action.type" placeholder="请选择">
          <el-option
            v-for="item in actionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <template v-if="model.action.type === 'link'">
        <el-form-item label="路径">
          <el-input v-model="model.action.path" />
        </el-form-item>
      </template>
      <template v-if="model.action.type === 'dialog'">
        <el-form-item label="标题">
          <el-input v-model="model.action.title" />
        </el-form-item>
        <el-form-item label="组件路径">
          <el-input v-model="model.action.component" />
        </el-form-item>
      </template>
      <template v-if="model.action.type === 'openPage'">
        <el-form-item label="页面url">
          <el-input v-model="model.action.path" />
        </el-form-item>
      </template>
      <template v-if="model.action.type === 'api'">
        <el-form-item label="接口url">
          <el-input v-model="model.action.api" />
        </el-form-item>
        <el-form-item label="是否提示确认">
          <el-switch v-model="model.action.confirm" />
        </el-form-item>
      </template>
    </template>

    <el-form-item>
      <el-button type="primary" size="small" @click="handleSubmit(model)">
        确认
      </el-button>
      <el-button size="small" @click="handleCancel(model)">
        取消
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { pick } from 'lodash';
import { resolveDefault } from '@/utils';
import { mapActions, mapState } from "vuex";
import { addMenu, updateMenu } from '@/api/finder';
import { FinderManager } from "@shopex/finder";
function resolveActionModel (info) {
  const data = pick(info, [
    'id',
    'action',
    'isEnable',
    'component',
    'hasChildren',
    'menuPath',
    'orderSort',
    'slot',
    'extra',
    'resourceIcon',
    'resourceName',
    'resourceParentId',
    'resourceType'
  ]);

  return resolveDefault({
    action: {},
    extra: {},
    isEnable: true,
    hasChildren: false,
    resourceType: 'menu'
  }, data);
}

export default {
  // inject: ['$resources'],

  props: {
    data: {
      type: Array
    },
    action: {
      type: String
    },
    menus: {
      type: Array
    }
  },

  data () {
    const buttonTypes = [
      { label: "文字按钮", value: "text" },
      { label: "主要按钮", value: "primary" },
      { label: "成功按钮", value: "success" },
      { label: "警告按钮", value: "warning" },
      { label: "危险按钮", value: "danger" },
      { label: "信息按钮", value: "info" }
    ];

    const actionOptions = [
      { label: "跳转页面", value: "link" },
      { label: "打开对话框", value: "dialog" },
      { label: "打开新页面", value: "openPage" },
      { label: "调用接口", value: "api" },
      { label: "Finder查看详情", value: "viewDetail" }
    ];

    return {
      actionOptions,
      buttonTypes,
      model: {},
      props: {
        value: 'id',
        label: 'resourceName',
        children: 'children',
        multiple: false,
        checkStrictly: true
      }
    };
  },
  watch: {
    data: {
      immediate: true,
      handler (val) {
        if (this.action === 'addChild') {
          const data = {
            resourceParentId: val[0].id
          };
          this.model = resolveActionModel(data);
          console.log('this.model', this.model);
          return;
        }
        this.model = resolveActionModel(val[0]);
      }
    },
  },
  methods: {
    ...mapActions({
      getMenuList: 'menu/getMenuList'
    }),
    handleChangeParentId (val) {
      this.model.resourceParentId = val[val.length - 1];
    },
    handleCancel () {
      this.$emit('close');
    },
    handleChangeType () {
    },
    async handleSubmit () {
      const url = this.model.id === undefined ? addMenu : updateMenu;
      this.model.resourceParentId = this.model.resourceParentId || 0;
      await this.$http.post(url, this.model);
      await this.getMenuList();
      this.$emit('change', this.model);
      this.$emit('close');
      if (this.model.id === undefined) {
        this.$emit('finder-refresh');
        const finder = FinderManager.getCurrentFinder();
        if (finder && this.model.resourceParentId) {
          finder.$refs.table.data.map(row => {
            if ((row.id === this.model.resourceParentId) && finder.resolveInfo[row.id]) {
              finder.handleLoadNode(row, {}, finder.resolveInfo[row.id]);
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-detail {
  padding: 10px 20px;
}
</style>
